import { t } from '@/i18n/translation_util';
import { useIsClient } from 'usehooks-ts';

import { Skeleton } from '@/components/ui/skeleton';
import {
  getCheckoutAmplitudeABTest,
  trackLogCtaClicked,
} from '@/lib/amplitude-analytics';
import { taboolaTrackStartCheckout } from '@/lib/event-trackers';
import { logger } from '@/lib/logger';
import { defaultTrialDays } from '@/lib/promo-codes';

export function CTAButtonTryApp({
  className,
  btnText,
  hrefOverride,
}: {
  className: string;
  btnText?: string;
  hrefOverride?: string;
}) {
  const isClient = useIsClient();

  if (!isClient) {
    return (
      <Skeleton className="h-[45px] w-full min-w-[275px] max-w-[275px] rounded-3xl bg-white shadow-[0px_4.303px_36.578px_0px_rgba(193,216,251,0.50)]" />
    );
  }

  const href =
    hrefOverride ??
    (getCheckoutAmplitudeABTest() == 'checkout-new'
      ? `/checkout${window.location.search}` // TODO: l(`/checkout${window.location.search}`) when we have checkout pages for each language
      : `#checkout`);

  return (
    <a
      data-cta-button
      onClick={(e) => {
        logger.log('CTA button clicked');
        trackLogCtaClicked();
        const clickedAnchor = e.target as HTMLAnchorElement;

        const removeTrailingSlash = (str: string) => str.replace(/\/$/, '');

        if (
          removeTrailingSlash(location.pathname) ===
            removeTrailingSlash(clickedAnchor.pathname) &&
          location.hostname === clickedAnchor.hostname
        ) {
          const target = document.querySelector(clickedAnchor.hash);
          if (target) {
            e.preventDefault();
            window.scrollTo({
              top: target.getBoundingClientRect().top + window.scrollY,
              behavior: 'smooth',
            });
          }
        }
        taboolaTrackStartCheckout();
      }}
      href={href}
      className={className}
    >
      {btnText ??
        t('Try the app free for x days', {
          trialDays: defaultTrialDays.toString(),
        })}
    </a>
  );
}
